import React, { useState, useRef, useEffect, Component } from "react"
import AnimatedHeadline from "../components/AnimatedHeadline"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import SEO from "../components/seo"
import Modal from "react-modal"

import Kontakt from "../components/kontakt"
import { TimelineMax, gsap, Power2 } from "gsap"

import Footer from "../components/footer"

import ogImagePlayground from "../images/og-image/og-image-playground.png"
function Playground({ data }) {
 
  let timeline = new TimelineMax()
  const websites = data.allWordpressWpWebsites.edges
  const mobiles = data.allWordpressWpMobile.edges.sort(({ node }, b) => {
    return node.acf.wordpress_id - b.node.acf.wordpress_id
  })

  const illustrations = data.allWordpressWpIllustration.edges
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }
  const [display, setDisplay] = useState(websites)

  const [currentItem, setCurrentItem] = useState({})

  const [modalIsOpen, setIsOpen] = React.useState(false)



 
  function openModal() {
    setIsOpen(true)
  }
  function afterOpenModal() {}
  function closeModal() {
    setIsOpen(false)
  }

  function animateTransition(type) {
    if (timeline.isActive() || type === display) {
      return
    }
    timeline.fromTo(
      ".cb-playground-grid-item-overlay",
      0.5,
      { x: "-100%" },
      {
        x: "0%",

        onComplete: () => {
          if (timeline.isActive() || type === display) {
            return
          }

          setDisplay(type)
          timeline.fromTo(
            ".cb-playground-grid-item-overlay",
            0.75,
            {
              x: "0%",
            },
            {
              x: "100%",
              ease: "slow(0.7, 0.7, false)",
            }
          )
        },
      }
    )
  }

  const designs = display.map(({ node: item }) => {
    return (
      <div
        key={item.title}
        onClick={() => {
          if (window && window.innerWidth >= 1024) {
            setCurrentItem(item)
            openModal()
          }
        }}
        className="cb-playground-grid-item"
      >
        {item.featured_media && (
          <Image
            fadeIn={true}
            fluid={item.featured_media.localFile.childImageSharp.fluid}
            alt={item.id}
          />
        )}
        <div class="cb-playground-grid-item-overlay"> </div>
      </div>
    )
  })

  return (
    <>
        <SEO
        title="Playground. Tauchen Sie ein in die Vielfalt unserer Designs."
        description="Verschaffen Sie sich einen ersten Eindruck. Tauchen Sie ein in die Vielfalt unserer Designs. Egal ob mobile Design, Landingpages oder Illustrationen, für jeden ist etwas dabei."
        image={ogImagePlayground}
        defaultTitle="Playground - Cuuno"
        titleTemplate="Playground"
      />
      <div className="cb-container">
        <div className="cb-herosection">
          <div className="cb-herosection-wrap">
            <AnimatedHeadline
              scrollTo = {"#playground"}
              offset = {-100}
              mobileText="Verschaffen Sie sich einen ersten Eindruck. Tauchen Sie ein in die Vielfalt unserer Designs."
              desktopText={[
                "Verschaffen Sie sich einen ersten ",
                "Eindruck. Tauchen Sie ein in die ",
                "Vielfalt unserer Designs."
              ]}
            />
       
          </div>
        </div>
        <ul class="cb-playground-nav">
          <li
            onClick={() => {
              animateTransition(websites)
            }}
          >
            <span
              className={`cb-playground-nav-label ${display === websites &&
                "active"}`}
            >
              Websites
            </span>
            <span className="cb-playground-nav-count">{websites.length}</span>
          </li>
          <li
            onClick={() => {
              animateTransition(mobiles)
            }}
          >
            <span
              className={`cb-playground-nav-label ${display === mobiles &&
                "active"}`}
            >
              Mobiles
            </span>
            <span className="cb-playground-nav-count">{mobiles.length}</span>
          </li>
          <li
            onClick={() => {
              animateTransition(illustrations)
            }}
          >
            <span
              className={`cb-playground-nav-label ${display === illustrations &&
                "active"}`}
            >
              Illustrations
            </span>
            <span className="cb-playground-nav-count">
              {illustrations.length}
            </span>
          </li>
        </ul>

        <div id="playground" className="cb-playground-grid">
          {designs}
        </div>
      </div>

      <Kontakt />
      <Footer />
      <div>
        <Modal
          className="modal"
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {modalIsOpen && (
            <Image
              fluid={currentItem.featured_media.localFile.childImageSharp.fluid}
              alt={currentItem.id}
            />
          )}
        </Modal>
      </div>
    </>
  )
}

export default Playground

export const pageQuery = graphql`
  query playgroundQuery {
    allWordpressWpWebsites {
      edges {
        node {
          id
          title

          date(formatString: "MMMM DD, YYYY")
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    allWordpressWpMobile {
      edges {
        node {
          id
          title
          acf {
            wordpress_id
          }

          date(formatString: "MMMM DD, YYYY")
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    allWordpressWpIllustration {
      edges {
        node {
          id
          title

          date(formatString: "MMMM DD, YYYY")
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
